// Mastersheet.js
import React from 'react';

const Mastersheetform = ({ formData, handleChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-1">
        <label htmlFor="item" className="form-label">Item</label>
        <input type="text" className="form-control" id="item" name="item" value={formData.item} onChange={handleChange} required />
      </div>
      <div className="mb-1">
        <label htmlFor="buyprice" className="form-label">B.P</label>
        <input type="number" className="form-control" id="buyprice" name="buyprice" value={formData.buyprice} onChange={handleChange} required />
      </div>
      <div className="mb-1">
        <label htmlFor="item" className="form-label">S.P</label>
        <input type="sellprice" className="form-control" id="sellprice" name="sellprice" value={formData.sellprice} onChange={handleChange} required />
      </div>
      <div className="mb-1">
        <label htmlFor="itemqty" className="form-label">QTY</label>
        <input type="number" className="form-control" id="itemqty" name="itemqty" value={formData.itemqty} onChange={handleChange} required />
      </div>
      <button type="submit" className="btn btn-success btn-sm">Submit</button>
    </form>
  );
};

export default Mastersheetform;
