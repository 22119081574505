import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

export default function Auth({ children }) {
  const cookies = new Cookies();
  const jwtCookie = cookies.get('jwt'); // Retrieve the value of the 'jwt' cookie

  if (jwtCookie) {
    return children; // Render the children component if the 'jwt' cookie is available
  }

  return <Navigate to="/" />; // Redirect to the desired page (e.g., login page)
}