import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SidePanel from './Sidepanel';
import Navbar from './Navbar';
import Dashboard from '../pages/dashboard/Dashboard';
import Users from '../pages/homepage/Users';
import Customers from '../pages/homepage/Customers';
import Tasks from '../pages/homepage/Tasks';
import Notes from '../pages/homepage/Notes';
import Orders from '../pages/homepage/Orders'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchsalesDetails, fetchingAllSellers, deleteSaleitem, editSaleItem, approveSale } from '../api/api';

export default function OrderDetails() {
  const { id } = useParams(); // Get the sales id from the route parameters
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');
  const [sellers, setSellers] = useState([]);
  const [sellid, setSellid] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0); // Initialize totalAmount state

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // logic 
  const [sales, setSales] = useState([]);
  const [formData, setFormData] = useState({
    sale: '',
    amount: '',
    username: localStorage.getItem('username')
  });
  const [selectedLabel, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchLabelItems();
    fetchSellers()
  }, []);

  const fetchLabelItems = async () => {
    try {
      const sales = await fetchsalesDetails(id);
      console.log('sales',sales);
      setSales(sales);
      setSellid(sales[0].saleid)
      // Calculate total amount for initial sales data
      const initialTotalAmount = sales.reduce((total, sale) => total + parseFloat(sale.saletotal), 0);
      setTotalAmount(initialTotalAmount);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const fetchSellers = async () => {
    try {
      const sellers = await fetchingAllSellers();
      console.log(sellers);
      setSellers(sellers);
    } catch (error) {
      console.error('Error fetching sellers:', error);
    }
  };

  const handleApprove = async (saleId) => {
    const isConfirmed = window.confirm("Are you sure you want to approve this sale?");
    if (isConfirmed) {
      try {
        await approveSale(saleId);
        // fetchLabelItems();
        // fetchSellers()      
        // toast.success('Sale approved successfully.');
        navigate('/approvesale')
      } catch (error) {
        toast.error('Error approving Sale. Please try again.');
      }
    }
  };


  const handleDelete = async (saleId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Item?");
    if (isConfirmed) {
      try {
        await deleteSaleitem(saleId);
        fetchLabelItems();
        toast.success('Item deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Item. Please try again.');
      }
    }
  };

  // for search 
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter items based on search query
  const filteredItems = sales.filter(item =>
    item.customer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleEdit = async (saleId, editedSaleData) => {
    try {
      await editSaleItem(saleId, editedSaleData);
      fetchLabelItems();
      toast.success('Item edited successfully.');
    } catch (error) {
      toast.error('Error editing Item. Please try again.');
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedSales = sales.map((sale, i) => {
      if (i === index) {
        sale[field] = value;
        sale.saletotal = sale.saleprice * sale.saleqty;
      }
      return sale;
    });
    setSales(updatedSales);

    const newTotalAmount = updatedSales.reduce((total, sale) => total + parseFloat(sale.saletotal), 0);
    setTotalAmount(newTotalAmount);
  };

  const handleSubmit = async () => {
    try {
      // Call API to update sales data
      await Promise.all(sales.map(sale => handleEdit(sale.id, sale)));
      toast.success('All items updated successfully.');
    } catch (error) {
      toast.error('Error updating items. Please try again.');
    }
  };


  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />

        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>

            <h6><u>Order Details</u></h6>

            <table className="table table-striped">
              <thead>
                <tr>
                  <td><strong>#</strong></td>
                  <th>Item</th>
                  {/* <th>Label</th> */}
                  <th>QTY</th>
                  {/* <th>Price</th>
                  <th>Total</th>
                  <th>Sale Type</th>
                  <th>Status</th>
                  <th>Customer</th> */}
                  <th></th>                
                </tr>
              </thead>
              <tbody>
                {sales.map((sale, index) => (
                  <tr key={sale.id}>
                    <td>{index + 1}</td>
                    <td>{sale.itemid.item}</td>
                    {/* <td>{sale.label}</td> */}
                    <td>{sale.saleqty}</td>
                    {/* <td>{sale.saletotal}</td>
                    <td>{sale.saletype}</td>
                    <td>{sale.salestatus === 0 ? <p className='text-danger'>Not sold</p> : <p>Sold</p>}</td>
                    <td>{sale.customer}</td> */}
                    <td>
                      <i className="fa-solid fa-delete-left fa-1x" onClick={() => handleDelete(sale.id)} style={{ paddingLeft: '10px' }}></i>
                    </td>
                  </tr>
                ))}
              </tbody>        
            </table>
            <ToastContainer />
          </div>
          }
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  )
}
