// Containerform.js

import React from 'react';

const Containerform = ({ formData, handleChange, handleSubmit, labels }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-1">
        <label htmlFor="container" className="form-label">Select Label</label>
        <select required className="form-select" aria-label="Default select example" value={formData.label} onChange={handleChange} name="labelid">
          <option disabled selected value="">Open to select label</option>
          {labels && labels.map(label => (
            <option key={label.id} value={label.id}>{label.labelname}</option>
          ))}
        </select>
        <label htmlFor="container" className="form-label">Container Name</label>
        <input type="text" className="form-control" id="container" name="container" value={formData.container} onChange={handleChange} required />
      </div>  
      <button type="submit" className="btn btn-success btn-sm">Submit</button>
    </form>
  );
};

export default Containerform;
