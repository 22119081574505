import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from './Dashboard';
import Users from '../homepage/Users';
import Customers from '../homepage/Customers';
import Tasks from '../homepage/Tasks';
import Notes from '../homepage/Notes';
import { Link } from 'react-router-dom';
import Orders from '../homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Mastersheetform from '../../components/Mastersheetform';
import { fetchAllSalesStatus, fetchAllSales, updateItem, deleteSale, approveSale } from '../../api/api';

export default function Solditems() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [selectedFile, setSelectedFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  const [sales, setSales] = useState([]);
  const [formData, setFormData] = useState({
    item: '',
    itemqty: '',
    buyprice: '',
    sellprice: ''
  });
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchSales();
  }, []);

  const fetchSales = async () => {
    try {
      const salesData = await fetchAllSales();
      // Filter sales data to include only invoices with approve_status of 1
      const approvedSales = salesData.filter(sale => sale.approvestatus === 1);
      setSales(approvedSales);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateItem(selectedTask.id, formData);
      fetchSales();
      toast.success('Item updated successfully.');
      setSelectedTask(null);
    } catch (error) {
      toast.error('Error updating Item. Please try again.');
    }
  };

  const handleUpdate = (item) => {
    setSelectedTask(item);
    setFormData({
      item: item.item,
      itemqty: item.itemqty
    });
  };

  const handleDelete = async (saleId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this sale?");
    if (isConfirmed) {
      try {
        await deleteSale(saleId);
        fetchSales();
        toast.success('Sale deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Sale. Please try again.');
      }
    }
  };

  const handleApprove = async (saleId) => {
    const isConfirmed = window.confirm("Are you sure you want to approve this sale?");
    if (isConfirmed) {
      try {
        await approveSale(saleId);
        fetchSales();
        toast.success('Sale approved successfully.');
      } catch (error) {
        toast.error('Error approving Sale. Please try again.');
      }
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Group sales by saleid and calculate totals
  const groupedSales = sales.reduce((acc, curr) => {
    if (!acc[curr.saleid]) {
      acc[curr.saleid] = {
        saleid: curr.saleid,
        qty: 0,
        cash: 0,
        paymentMethod: curr.payment,
        saleType: curr.saletype,
        customer: curr.customer,
        invoicenumber: curr.invoicenumber,
        seller: curr.seller,
        createdAt: curr.createdAt
        
      };
    }
    acc[curr.saleid].qty += curr.saleqty;
    acc[curr.saleid].cash += curr.saleprice * curr.saleqty;
    return acc;
  }, {});

  // Filter grouped sales based on search query
  const filteredSales = Object.values(groupedSales).filter(sale =>
    sale.invoicenumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-5'>
          
          <div className='float-end d-flex'>
          {/* <Link to='/invoices'><button className='btn btn-success'>Invoices</button></Link> */}
          <Link to='/invoices'><button className='btn btn-warning mx-1'>Listed</button></Link>
          <input
              className='form-control'
              type="text"
              placeholder="Search invoice..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <h5><u>Sold Items</u></h5>

          <table className="table table-striped mt-2">
            <thead>
              <tr>
                <th><strong>#</strong></th>
                {/* <th>Sale ID</th> */}
                <th>Invoice</th>
                <th>Client</th>
                <th>Date</th>
                <th>Creator</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredSales.length < 1 ? (
                <div>
                  <h5>There are Zero Invoices</h5>
                </div>
              ) : (filteredSales.map((sale, index) => (
                <tr key={sale.saleid}>
                  <td>{index + 1}</td>
                  {/* <td>{sale.saleid}</td> */}
                  <td>{sale.invoicenumber}</td>
                  <td>{sale.customer}</td>
                  <td>{sale.createdAt}</td>
                  <td>{sale.seller}</td>
                  <td>
                    <Link to={`/solditemsdetails/${sale.saleid}`}><i className="fa-solid fa-eye fa-1x" style={{ paddingLeft: '10px'}}></i></Link>
                    {/* <i className="fa-solid fa-delete-left fa-1x mx-2" onClick={() => handleDelete(sale.saleid)}></i> */}
                  </td>
                </tr>
              )))}
            </tbody>
          </table>
          <ToastContainer />
        </div>
          }
          
          
          
          {/* others begin here  */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
