// Expenseform.js
import React from 'react';

const Expenseform = ({ formData, handleChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <input type="text" className="form-control d-none" id="username" name="username" value={formData.username} onChange={handleChange} />
      <div className="mb-1">
        <label htmlFor="expense" className="form-label">Expense</label>
        <input type="text" className="form-control" id="expense" name="expense" value={formData.expense} onChange={handleChange} required />
      </div>    
      <div className="mb-1">
        <label htmlFor="amount" className="form-label">Amount</label>
        <input type="number" className="form-control" id="amount" name="amount" value={formData.amount} onChange={handleChange} required />
      </div>    
      <button type="submit" className="btn btn-success btn-sm">Submit</button>
    </form>
  );
};

export default Expenseform;
