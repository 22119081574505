// UserForm.js
import React from 'react';

const Userform = ({ formData, handleChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-1">
        <label htmlFor="username" className="form-label">Username</label>
        <input type="text" className="form-control" id="username" name="username" value={formData.username} onChange={handleChange} required />
      </div>
      <div className="mb-1">
        <label htmlFor="department" className="form-label">Department</label>
        <select className="form-select" id="department" name="department" value={formData.department} onChange={handleChange} required>
          <option disabled value="">Select Department</option>
          <option value="sales">Sales</option>
          <option value="accounts">Accounts</option>
        </select>
      </div>
      <div className="mb-1">
        <label htmlFor="designation" className="form-label">Designation</label>
        <select className="form-select" id="designation" name="designation" value={formData.designation} onChange={handleChange} required>
          <option disabled value="">Select Designation</option>
          <option value="user">User</option>
          <option value="admin">Admin</option>
          <option value="superadmin">Super admin</option>
        </select>
      </div>
      <div className="mb-1">
        <label htmlFor="email" className="form-label">Email</label>
        <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
      </div>
      <div className="mb-1">
        <label htmlFor="phone" className="form-label">Phone</label>
        <input type="number" className="form-control" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
      </div>
      <div className="mb-1">
        <label htmlFor="password" className="form-label">Password</label>
        <input type="password" className="form-control" id="password" name="password" value={formData.password} onChange={handleChange} required />
      </div>
      <button type="submit" className="btn btn-success btn-sm">Submit</button>
    </form>
  );
};

export default Userform;
