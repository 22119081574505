import React from 'react'
import { Link } from 'react-router-dom'

export default function Dashboard() {

  return (
    <>
        <div className='row mt-3 text-center'>
        <div className='col-md-6 mt-3'>
            <Link to='/labels' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                        <i class="fa-solid fa-tags dashboardicons"></i>
                        <h5>Labels</h5>
                    </div>
                </div>
            </Link>
        </div>

        <div className='col-md-6 mt-3'>
            <Link to='/invoices' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-file-invoice-dollar dashboardicons"></i>
                    <h5>Invoices</h5>
                    </div>
                </div>
            </Link>
        </div>

        <div className='col-md-4 mt-3'>
            <Link to='/container' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-boxes-stacked dashboardicons"></i>
                    <h5>Container</h5>
                    </div>
                </div>
            </Link>
        </div>

        <div className='col-md-4 mt-3'>
            <Link to='/expenses' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-money-bill dashboardicons"></i>
                    <h5>Expenses</h5>
                    </div>
                </div>
            </Link>
        </div>

        {/* <div className='col-md-4 mt-3'>
            <Link to='/receivables'>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-truck dashboardicons"></i>
                    <h5>Receivables & Payables</h5>
                    </div>
                </div>
            </Link>
        </div> */}

        <div className='col-md-4 mt-3'>
            <Link to='/reports' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-table dashboardicons"></i>
                    <h5>Reports</h5>
                    </div>
                </div>
            </Link>
        </div>

        {/* <div className='col-md-4 mt-3'>
            <Link to='/cashpayment'>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-building-columns dashboardicons"></i>
                    <h5>Cash</h5>
                    </div>
                </div>
            </Link>
        </div> */}

        {/* <div className='col-md-4 mt-3'>
            <Link to='/bank'>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-building-columns dashboardicons"></i>
                    <h5>Bank</h5>
                    </div>
                </div>
            </Link>
        </div> */}

        {/* <div className='col-md-4 mt-3'>
            <Link to='/mpesa'>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-mobile-screen dashboardicons"></i>
                    <h5>Mpesa</h5>
                    </div>
                </div>
            </Link>
        </div> */}

        <div className='col-md-4 mt-3'>
            <Link to='/mastersheet' style={{textDecoration:  'none'}}>
            <div class="card">
                <div class="card-body">
                <i class="fa-solid fa-sheet-plastic dashboardicons"></i>
                <h5>Mastersheet</h5>
                </div>
            </div>
            </Link>
        </div>

        <div className='col-md-4 mt-3'>
            <Link to='/approvesale' style={{textDecoration:  'none'}}>
            <div class="card">
                <div class="card-body">
                <i class="fa-solid fa-sheet-plastic dashboardicons"></i>
                <h5>Approve Sales</h5>
                </div>
            </div>
            </Link>
        </div>

        <div className='col-md-4 mt-3'>
            <Link to='/sales' style={{textDecoration:  'none'}}>
            <div class="card">
                <div class="card-body">
                <i class="fa-solid fa-cart-shopping dashboardicons"></i>
                <h5>Sales</h5>
                </div>
            </div>
            </Link>
        </div>

        <div className='col-md-4 mt-3'>
            <Link to='/analytics' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-chart-simple dashboardicons"></i>
                    <h5>Analytics</h5>
                    </div>
                </div>
            </Link>
        </div>

        <div className='col-md-4 mt-3'>
            <Link to='/balereturn' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-person-walking-arrow-loop-left dashboardicons"></i>
                    <h5>Bale Return</h5>
                    </div>
                </div>
            </Link>
        </div>

        <div className='col-md-4 mt-3'>
            <Link to='/goodcomplain' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-comments dashboardicons"></i>
                    <h5>Good Complain</h5>
                    </div>
                </div>
            </Link>
        </div>

        </div>
    </>
  )
}
