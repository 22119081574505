import React, {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';

export default function Navbar() {
    const navigate = useNavigate();
    const [designation, setDesignation] = useState(''); // State to store the user's designation
    const cookies = new Cookies();
    const [isDarkMode, setIsDarkMode] = useState(false);

     // logout 
    const handleLogout = () => {
        // Clear user details from local storage
        localStorage.removeItem('userid');
        localStorage.removeItem('token');
        localStorage.removeItem('designation');
        localStorage.removeItem('username');

        // Clear cookies
        cookies.remove('jwt');
        cookies.remove('designation');
        window.location.href = '/'; // Redirect to the desired logout page or home page
    };

    const toggleDarkMode = () => {
        const newMode = !isDarkMode;
        setIsDarkMode(newMode);
        // Toggle the dark mode class on the body element
        document.body.classList.toggle('dark-mode', newMode);
        // Save the mode preference in local storage
        localStorage.setItem('isDarkMode', newMode);
    };

    // Function to check and apply dark mode from local storage on component mount
  const checkAndApplyDarkMode = () => {
    const savedMode = localStorage.getItem('isDarkMode');
    if (savedMode) {
      const newMode = savedMode === 'true'; // Convert the string back to boolean
      setIsDarkMode(newMode);
      document.body.classList.toggle('dark-mode', newMode);
    }
  };

  useEffect(() => {
    // Check for the user's designation from cookies
    const userDesignation = cookies.get('designation');
    setDesignation(userDesignation);

    // Check and apply dark mode on component mount
    checkAndApplyDarkMode();
  }, []);

  return (
    <>
        <nav class="navbar navbar-expand-md fixed-top">
            <div class="container">
            <a class="navbar-brand fw-bold" href="#"> SV Trading LTD </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                {/* <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item"> <a class="nav-link" href="#">Import</a> </li>
                    <li class="nav-item"> <a class="nav-link" href="#">Export</a> </li>
                </ul> */}
                <button className='btn btn-danger btn-sm ms-auto' onClick={handleLogout}>Log out</button>
            </div>
            </div>
        </nav>
    </>
  )
}
