// Tasks.js
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Taskform from '../../components/Taskform';
import { fetchAllTasks, createTask, updateTask, deleteTask } from '../../api/api';

export default function Tasks() {
  const [tasks, setTasks] = useState([]);
  const [formData, setFormData] = useState({
    task: '',
    status: '',
    priority: '',
    due: '',
    userid: localStorage.getItem('userid'),
  });
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const tasks = await fetchAllTasks(localStorage.getItem('userid'));
      setTasks(tasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createTask(formData);
      fetchTasks();
      toast.success('Task added successfully.!');
    } catch (error) {
      toast.error('Error adding task. Please try again.');
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateTask(selectedTask.id, formData);
      fetchTasks();
      toast.success('Task updated successfully.');
      setSelectedTask(null);
    } catch (error) {
      toast.error('Error updating task. Please try again.');
    }
  };

  const handleUpdate = (task) => {
    setSelectedTask(task);
    setFormData({
      task: task.task,
      status: task.status,
      priority: task.proprity,
      due: task.due,
    });
  };

  const handleDelete = async (userId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this task?");
    if (isConfirmed) {
      try {
        await deleteTask(userId);
        fetchTasks();
        toast.success('Task deleted successfully.');
      } catch (error) {
        toast.error('Error deleting task. Please try again.');
      }
    }
  };

  return (
    <>
      <div className='container mt-5'>
        <button className='btn btn-success btn-sm float-end' data-bs-toggle="modal" data-bs-target="#exampleModal">Create Task</button>

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title fs-5" id="exampleModalLabel">Add Task</h6>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <Taskform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
              </div>
            </div>
          </div>
        </div>

        <h5><u>Tasks</u></h5>
        <table className="table table-striped">
          <thead>
            <tr>
              <td><strong>#</strong></td>
              <th>Task</th>
              <th>Status</th>
              <th>Priority</th>
              <th>Due</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task, index) => (
              <tr key={task.id}>
                <td>{index + 1}</td>
                <td>{task.task}</td>
                <td>{task.status}</td>
                <td>{task.priority}</td>
                <td>{task.due}</td>
                <td>{task.createdAt}</td>
                <td>
                  <i className="fa-solid fa-pen-to-square fa-1x" onClick={() => handleUpdate(task)} data-bs-toggle="modal" data-bs-target="#exampleModal2"></i>
                  <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h6 className="modal-title fs-5" id="exampleModalLabel2">Update Task</h6>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <Taskform formData={formData} handleChange={handleChange} handleSubmit={handleUpdateSubmit} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <i className="fa-solid fa-delete-left fa-1x" onClick={() => handleDelete(task.id)} style={{ paddingLeft: '10px'}}></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer />
      </div>
    </>
  );
}
