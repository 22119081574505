import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import Containerform from '../../components/Containerform';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchingAllLabels, fetchingAllContainers, additemtoContainer, createContainer, importItemsCSV, updateContainer, deleteContainer } from '../../api/api';
import { v4 as uuidv4 } from 'uuid';

export default function Container() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [fileSelection, setFileSelection] = useState({});
  const [labels, setLabels] = useState([]);

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // logic 
  const [containers, setContainers] = useState([]);
  const [formData, setFormData] = useState({
    container: '',
    labelid: '',
    username: localStorage.getItem('username')
  });
  const [selectedContainer, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchContainers();
    fetchLabels();
  }, []);

  const fetchLabels = async () => {
    try {
      const labels = await fetchingAllLabels();
      setLabels(labels);
    } catch (error) {
      console.error('Error fetching labels:', error);
    }
  };

  const fetchContainers = async () => {
    try {
      const containers = await fetchingAllContainers();
      console.log(containers);
      setContainers(containers);
    } catch (error) {
      console.error('Error fetching containers:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createContainer(formData);
      fetchContainers();
      fetchLabels();
      toast.success('Container added successfully.!');
    } catch (error) {
      toast.error('Error adding Container. Please try again.');
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateContainer(selectedContainer.id, formData);
      fetchContainers();
      fetchLabels();
      toast.success('Container updated successfully.');
      setSelectedTask(null);
    } catch (error) {
      toast.error('Error updating Container. Please try again.');
    }
  };

  const submitAddedItemToContainer = async (e) => {
    e.preventDefault();
    try {
      const firstContainer = containers[0]; // Access the first object in the array

      // Construct the payload with the updated container details
      const payload = {
        code: uuidv4(),
        item: formData.item,
        masterqty: formData.masterqty,
        container: firstContainer.container,
        containerID: firstContainer.id,  
        labelid: firstContainer.labelid,  
        status: 'pending',   
        username: localStorage.getItem('username')
      };

      console.log(payload);
  
      const response = await additemtoContainer(payload);
  
      if (response.status === 200) {
        toast.success('Form data submitted successfully.');
        fetchContainers();
      } else {
        toast.error('Error submitting form data.');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
      toast.error('Error submitting form data. Please try again.');
    }
  };

  const handleUpdate = (container) => {
    setSelectedTask(container);
    setFormData({
      container: container.container,
      amount: container.amount
   });
  };

  const handleDelete = async (userId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Container?");
    if (isConfirmed) {
      try {
        await deleteContainer(userId);
        fetchContainers();
        fetchLabels();
        toast.success('Container deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Container. Please try again.');
      }
    }
  };

  const [file, setFile] = useState(null);

  const handleCsvChange = (e, containerId) => {
    setFileSelection({
      ...fileSelection,
      [containerId]: e.target.files[0] // Store the selected file for the container
    });
  };

  // Submit handler for CSV upload
  const handleCsvSubmit = async (e, containerId) => {
    e.preventDefault();
    const container = containers.find(container => container.id === containerId);
    const formData = new FormData();
    formData.append('csvFile', fileSelection[containerId]); // Use the selected file for the container
    formData.append('container', container.container);
    formData.append('label', container.label?.labelname || ''); // Add check for labelname
    formData.append('labelid', container.labelid);
    formData.append('containerID', container.id);
    formData.append('username', localStorage.getItem('username'));

    try {
      const response = await importItemsCSV(formData);

      console.log(response);
      toast.success(`${container.container} has been uploaded successfully`);

      // Clear file selection after successful upload
      setFileSelection({
        ...fileSelection,
        [containerId]: null
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error(`Error uploading ${container.container}. Please try again.`);
    }
  };

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && <div>
          <div className='mt-4'>  

          <button className='btn btn-success btn-sm float-end' data-bs-toggle="modal" data-bs-target="#exampleModal2">Create Container</button>

          <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title fs-5" id="exampleModalLabel2">Add Container</h6>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <Containerform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} labels={labels}  />
                </div>
              </div>
            </div>
          </div>

          <h6><u>Container Dashboard</u></h6>
          <table className="table table-striped">
          <thead>
            <tr>
              <td><strong>#</strong></td>
              <th>Container</th>
              <th>Label</th>
              <th>User</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {containers.length < 1 ? (
              <div>
                <h5>There are Zero containers</h5>
              </div>
            ) : (containers.map((container, index) => (
              <tr key={container.id}>
                <td>{index + 1}</td>
                <td>{container.container}</td>
                <td>{container.label?.labelname || ''}</td> {/* Add check for labelname */}
                <td>{container.username}</td>
                <td>{container.createdAt}</td>
                <td>
                  <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h6 className="modal-title fs-5" id="exampleModalLabel2">Update Container</h6>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <Containerform formData={formData} handleChange={handleChange} handleSubmit={handleUpdateSubmit} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <i className="fa-solid fa-delete-left fa-1x" onClick={() => handleDelete(container.id)} style={{ paddingLeft: '10px'}}></i>
                  <Link to={`/containerdetails/${container.id}`}><i className="fa-solid fa-eye fa-1x" style={{ paddingLeft: '10px'}}></i></Link>

                  <label htmlFor={`csvFile-${container.id}`} className="file-upload-icon">
                    <i className='fa-solid fa-file-import fa-1x mx-1' style={{ cursor: 'pointer', color: fileSelection[container.id] ? 'green' : 'black' }}></i>
                  </label>
                  <input
                    type="file" 
                    accept=".csv" 
                    id={`csvFile-${container.id}`} 
                    name={`csvFile-${container.id}`} 
                    onChange={e => handleCsvChange(e, container.id)} 
                    style={{ display: 'none' }} 
                  />

                  <button className='btn btn-success btn-sm mx-1' onClick={(e) => handleCsvSubmit(e, container.id)}>Upload</button>
                </td>
              </tr>
            )))}
          </tbody>
          </table>
          <ToastContainer />
          </div>
          </div>}

          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
