// Taskform.js
import React from 'react';

const Notesform = ({ formData, handleChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <input type="number" className="form-control d-none" id="userid" name="userid" value={formData.userid} onChange={handleChange} />
      <div className="mb-1">
        <label htmlFor="title" className="form-label">Title</label>
        <input type="text" className="form-control" id="title" name="title" value={formData.title} onChange={handleChange} required />
      </div>  
      <div className="mb-1">
        <label htmlFor="note" className="form-label">Note</label>
        <input type="text" className="form-control" id="note" name="note" value={formData.note} onChange={handleChange} required />
      </div>      
      <button type="submit" className="btn btn-success btn-sm">Submit</button>
    </form>
  );
};

export default Notesform;
