import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllstocks } from '../../api/api';

export default function Stocks() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');
  const [containers, setContainers] = useState([]);
  const [items, setItems] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchItems = async () => {
    try {
      const items = await fetchAllstocks();
      setItems(items);
      const uniqueContainers = [...new Set(items.map(item => item.label))];
      setContainers(uniqueContainers);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  useEffect(() => {
    // Set default date range to the current month
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const start = new Date(year, month, 1).toISOString().split('T')[0];
    const end = new Date(year, month + 1, 0).toISOString().split('T')[0];
    setStartDate(start);
    setEndDate(end);

    fetchItems();
  }, []);

  useEffect(() => {
    fetchItems();
  }, [startDate, endDate]);

  const getCurrentMonthDates = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const dates = [];
    for (let day = 1; day <= new Date(year, month + 1, 0).getDate(); day++) {
      dates.push(new Date(year, month, day).toLocaleDateString());
    }
    return dates;
  };

  const getDatesInRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dates = [];
    while (startDate <= endDate) {
      dates.push(new Date(startDate).toLocaleDateString());
      startDate.setDate(startDate.getDate() + 1);
    }
    return dates;
  };

  const currentMonthDates = getCurrentMonthDates();
  const filteredDates = getDatesInRange(startDate, endDate);

  const groupedItemsByLabelAndDate = items.reduce((acc, item) => {
    const date = new Date(item.createdAt).toLocaleDateString();
    if (!acc[item.label]) {
      acc[item.label] = {};
    }
    if (!acc[item.label][item.item]) {
      acc[item.label][item.item] = {};
    }
    if (!acc[item.label][item.item][date]) {
      acc[item.label][item.item][date] = { in: 0, out: 0, balance: 0 };
    }
    acc[item.label][item.item][date].in += item.instock;
    acc[item.label][item.item][date].out += item.outstock;
    acc[item.label][item.item][date].balance += item.stock;
    return acc;
  }, {});

  const filteredGroupedItemsByLabelAndDate = Object.keys(groupedItemsByLabelAndDate).reduce((acc, label) => {
    const filteredItemsByLabel = Object.keys(groupedItemsByLabelAndDate[label]).reduce((itemAcc, itemName) => {
      const filteredItemsByDate = filteredDates.reduce((dateAcc, date) => {
        const item = groupedItemsByLabelAndDate[label][itemName][date] || { in: 0, out: 0, balance: 0 };
        if (itemName.toLowerCase().includes(searchQuery.toLowerCase())) {
          dateAcc[date] = item;
        }
        return dateAcc;
      }, {});

      if (Object.keys(filteredItemsByDate).length > 0) {
        itemAcc[itemName] = filteredItemsByDate;
      }
      return itemAcc;
    }, {});

    if (Object.keys(filteredItemsByLabel).length > 0) {
      acc[label] = filteredItemsByLabel;
    }
    return acc;
  }, {});

  const generateCSVData = () => {
    const headers = ['Item'];
    filteredDates.forEach(date => {
      headers.push(`${date} In`);
      headers.push(`${date} Out`);
      headers.push(`${date} Balance`);
    });

    const csvData = [headers];
    Object.entries(filteredGroupedItemsByLabelAndDate).forEach(([label, itemsByLabel]) => {
      Object.entries(itemsByLabel).forEach(([itemName, itemsByDate]) => {
        const row = [itemName];
        filteredDates.forEach(date => {
          const item = itemsByDate[date] || { in: 0, out: 0, balance: 0 };
          row.push(item.in);
          row.push(item.out);
          row.push(item.balance);
        });
        csvData.push(row);
      });
    });
    return csvData;
  };

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Mastersheet */}
          {activeScreen === 'mastersheet' && (
            <div className='mt-5'>
              <CSVLink
                data={generateCSVData()}
                filename={"mastersheet-report.csv"}
                className="btn btn-success btn-sm mx-1 float-end"
              >
                Export to CSV
              </CSVLink>

              <input
                className='form-control w-25 float-end'
                type="text"
                placeholder="Search items..."
                value={searchQuery}
                onChange={handleSearchChange}
              />

              <input
                type='date'
                id='endDate'
                className='form-control float-end w-25 mx-1'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />

              <input
                type='date'
                id='startDate'
                className='form-control float-end w-25 mx-1'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />

              <h5><u>Mastersheet</u></h5>
              {Object.keys(filteredGroupedItemsByLabelAndDate).length === 0 ? (
                <h5>Mastersheet has zero data</h5>
              ) : (
                Object.keys(filteredGroupedItemsByLabelAndDate).map((label, labelIndex) => (
                  <div key={labelIndex} className='mt-3 text-success' style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'auto' }}>
                    <h4 className='sticky-column'><u>{label}</u></h4>
                    <table className="table table-striped" style={{ minWidth: '100%' }}>
                      <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
                        <tr>
                          <th className='sticky-column' style={{zIndex:2}}>Item</th>
                          {filteredDates.map((date, dateIndex) => (
                            <React.Fragment key={dateIndex}>
                              <th colSpan="3" style={{ border: '1px solid #dee2e6', textAlign: 'center', zIndex:2 }}>{date}</th>
                            </React.Fragment>
                          ))}
                        </tr>
                        <tr>
                          <th className='sticky-column' style={{zIndex:2}}></th>
                          {filteredDates.map((date, dateIndex) => (
                            <React.Fragment key={dateIndex}>
                              <th style={{ border: '1px solid #dee2e6' }}>In</th>
                              <th style={{ border: '1px solid #dee2e6' }}>Out</th>
                              <th style={{ border: '1px solid #dee2e6' }}>Balance</th>
                            </React.Fragment>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(filteredGroupedItemsByLabelAndDate[label]).map((itemName, itemIndex) => (
                          <tr key={itemIndex}>
                            <td className='sticky-column' style={{minWidth: '200px', zIndex:2}}>{itemName}</td>
                            {filteredDates.map((date, dateIndex) => (
                              <React.Fragment key={dateIndex}>
                                <td style={{ border: '1px solid #dee2e6', color: 'green' }}>{filteredGroupedItemsByLabelAndDate[label][itemName][date]?.in || 0}</td>
                                <td style={{ border: '1px solid #dee2e6', color: 'orange' }}>{filteredGroupedItemsByLabelAndDate[label][itemName][date]?.out || 0}</td>
                                <td style={{ border: '1px solid #dee2e6', color: 'red' }}>{filteredGroupedItemsByLabelAndDate[label][itemName][date]?.balance || 0}</td>
                              </React.Fragment>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))
              )}
              <ToastContainer />
            </div>
          )}

          {/* Side panel components */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
