// Labelform.js
import React from 'react';

const Labelform = ({ formData, handleChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-1">
        <label htmlFor="labelname" className="form-label">Label Name</label>
        <input type="text" className="form-control" id="labelname" name="labelname" value={formData.labelname} onChange={handleChange} required />
      </div>  
      <button type="submit" className="btn btn-success btn-sm">Submit</button>
    </form>
  );
};

export default Labelform;
