// Maxform.js
import React from 'react';

const Maxform = ({ formData, handleChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
       <input type="text" className="form-control" id="maxnum" name="maxnum" value={formData.expense} onChange={handleChange} required />  
       <button type="submit" className="btn btn-success btn-sm mt-3">Add</button>
    </form>
  );
};

export default Maxform;