import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Mastersheetform from '../../components/Mastersheetform';
import { fetchAllItemsMaster, createItemMaster, updateStockMaster, fetchAllstocks } from '../../api/api'; // Add updateStockMaster

export default function Mastersheet() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');
  const [containers, setContainers] = useState([]);
  const [showStockInputs, setShowStockInputs] = useState(false); // State for toggling inputs
  const [updatedStocks, setUpdatedStocks] = useState({}); // State for storing updated stocks
  const [totalbales, setTotalbales] = useState(0)
  const [todaysoldBales, setTodayssoldbales] = useState(0)

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  const toggleStockInputs = () => {
    setShowStockInputs(!showStockInputs);
  };

  const handleStockChange = (item, value) => {
    setUpdatedStocks({
      ...updatedStocks,
      [item]: value,
    });
  };

  const handleStockSubmit = async () => {
    try {
      await updateStockMaster(updatedStocks);
      fetchItems();
      toast.success('Stock updated successfully.');
    } catch (error) {
      toast.error('Error updating stock. Please try again.');
    }
  };

  // Items logic starts here 
  const [items, setItems] = useState([]);
  const [formData, setFormData] = useState({
    item: '',
    itemqty: '',
    buyprice: '',
    sellprice: ''
  });

  useEffect(() => {
    fetchItems();
    fetchAllStocks()
  }, []);

  const fetchItems = async () => {
    try {
      const items = await fetchAllItemsMaster();
      const filteredItems = items.filter(item => item.status !== 0);
      console.log('Filtered items', filteredItems);
      setItems(filteredItems);
      // Extract unique container names from items array
      const uniqueContainers = [...new Set(items.map(item => item.label))];
      setContainers(uniqueContainers);
      const total = filteredItems.reduce((accumulator, item) => accumulator + item.masterqty, 0);
      setTotalbales(total);
      
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };  

  const fetchAllStocks = async () => {
    try {
      const stocks = await fetchAllstocks();
      console.log('Fetch all stocks', stocks);

      // Get today's date in the same format as the createdAt value
      const today = new Date().toISOString().split('T')[0]; // 'YYYY-MM-DD' format

      // Filter stocks created today
      const todayStocks = stocks.filter(stock => stock.createdAt.startsWith(today));

      // Calculate the total outstock for today's stocks
      const totalOutstock = todayStocks.reduce((accumulator, stock) => accumulator + stock.outstock, 0);

      setTodayssoldbales(totalOutstock)
      console.log('Total outstock for today:', totalOutstock);
    } catch (error) {
      console.error('Error fetching stocks:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createItemMaster(formData);
      fetchItems();
      toast.success('Item added successfully.');
    } catch (error) {
      toast.error('Error adding item. Please try again.');
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Group items by label
  const groupedItems = items.reduce((acc, item) => {
    if (!acc[item.label]) {
      acc[item.label] = [];
    }
    acc[item.label].push(item);
    return acc;
  }, {});

  // Filter items based on search query within each label group
  const filteredGroupedItems = Object.keys(groupedItems).reduce((acc, label) => {
    const filteredItems = groupedItems[label].filter(item =>
      item.item.toLowerCase().includes(searchQuery.toLowerCase())
    );
    if (filteredItems.length > 0) {
      acc[label] = filteredItems;
    }
    return acc;
  }, {});

  // CSV headers
  const csvHeaders = [
    { label: "Item", key: "item" },
    { label: "Label", key: "label" },
    { label: "In", key: "inqty" },
    { label: "Out", key: "out" },
    { label: "Balance", key: "masterqty" }
  ];

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Mastersheet */}
          {activeScreen === 'mastersheet' &&
            <div className='mt-5'>
              <div className="modal fade" id="exampleModalcsv" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title fs-5" id="exampleModalLabel">Import CSV</h6>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <Mastersheetform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title fs-5" id="exampleModalLabel">Add Item</h6>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <Mastersheetform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
                    </div>
                  </div>
                </div>
              </div>

              {/* <CSVLink
                  data={Object.values(filteredGroupedItems).flat()}
                  headers={csvHeaders}
                  filename={"mastersheet-report.csv"}
                  className="btn btn-success btn-sm mx-1 float-end"
                >
                  Export to CSV
              </CSVLink> */}

              <button className="btn btn-primary btn-sm mx-1 float-end" onClick={toggleStockInputs}>
                {showStockInputs ? 'Hide Stock Inputs' : 'Show Stock Inputs'}
              </button>

              <button className="btn btn-success btn-sm mx-1 float-end" onClick={handleStockSubmit}>
                Submit Stock Updates
              </button>

              <input
                className='form-control w-25 float-end'
                type="text"
                placeholder="Search items..."
                value={searchQuery}
                onChange={handleSearchChange}
              />

              <Link to='/stocks'><button className='btn btn-warning btn-sm float-end mx-1'>Stocks</button></Link>

              <h5><u>Mastersheet Page</u></h5>
              <h6>Total Bales: {totalbales}</h6>
              <h6>Today's Total Bale Sales: {todaysoldBales}</h6>

              {containers.length < 1 ? (
                <h5>Mastersheet has zero data</h5>
              ) : (
                Object.keys(filteredGroupedItems).map((label, index) => (
                  <div className='mt-3 text-success' key={index}>
                    <h3 className=''><u>{label}</u></h3>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Daily Out</th>
                          <th>Stock</th>
                          {showStockInputs && <th>Update Stock</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredGroupedItems[label].map((item, index) => (
                          <tr key={index}>
                            <td>{item.item}</td>
                            <td>{item.dailyout}</td>
                            <td>{item.masterqty}</td>
                            {showStockInputs && (
                              <td>
                                <input
                                  type="number"
                                  value={updatedStocks[item.id] || item.masterqty}
                                  onChange={(e) => handleStockChange(item.id, e.target.value)}
                                  className="form-control"
                                />
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))
              )}
              <ToastContainer />
            </div>
          }

          {/* Side panel components */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
