import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { createComplain } from '../api/api';

const Goodcomplainform = ({ masteritems, saleId, onRefresh, customerName }) => {
  const [forms, setForms] = useState([{ id: '', compqty: '', compamount: '', saleId, customerName }]);
  const [filteredItems, setFilteredItems] = useState(masteritems || []);

  const handleItemChange = (index, e) => {
    const { value } = e.target;
    const newForms = [...forms];
    newForms[index] = { ...newForms[index], [e.target.name]: value };
    setForms(newForms);

    // Filter items based on input value
    const filtered = masteritems.filter(item => item.item.toLowerCase().includes(value.toLowerCase()));
    setFilteredItems(filtered);
  };

  const handleChange = (index, e) => {
    const newForms = [...forms];
    newForms[index] = { ...newForms[index], [e.target.name]: e.target.value };
    setForms(newForms);
  };

  const handleAddForm = () => {
    setForms([...forms, { id: '', compqty: '', compamount: '', saleId }]);
  };

  const handleRemoveForm = (index) => {
    const newForms = forms.filter((_, formIndex) => formIndex !== index);
    setForms(newForms);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createComplain(forms);
      toast.success('Complain added successfully!');
      onRefresh(); // Trigger the refresh in the parent component
    } catch (error) {
      toast.error('Error adding complain. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {forms.map((form, index) => (
        <div key={index} className='row mb-3'>
          <div className='col-md-3'>
            <label>Item</label>
            <input
              type="text"
              name='id'
              required
              className="form-control"
              value={form.id}
              onChange={(e) => handleItemChange(index, e)}
              list={`item-list-${index}`}
            />
            <datalist id={`item-list-${index}`}>
              {filteredItems.map(item => (
                <option key={item.id} value={item.id}>
                  {item.item + ' - ' + item.labelitem.labelname}
                </option>
              ))}
            </datalist>
          </div>
          <div className='col-md-3'>
            <label>QTY</label>
            <input
              type="number"
              min={1}
              name='compqty'
              required
              className="form-control"
              value={form.compqty}
              onChange={(e) => handleChange(index, e)}
            />
          </div>
          <div className='col-md-3'>
            <label>Amount</label>
            <input
              type="number"
              min={1}
              name='compamount'
              required
              className="form-control"
              value={form.compamount}
              onChange={(e) => handleChange(index, e)}
            />
          </div>
          <div className='col-md-3'>
            <button type="button" className='btn btn-danger btn-sm mt-4' onClick={() => handleRemoveForm(index)}>
              <i className="fa-solid fa-trash"></i>
            </button>
          </div>
        </div>
      ))}
      
      <button type="button" className='btn btn-primary btn-sm mb-3' onClick={handleAddForm}>
        <i className="fa-solid fa-plus"></i>
      </button>
      <div>
        <button type='submit' className='btn btn-warning btn-sm'>Complain</button>
      </div>
    </form>
  );
};

export default Goodcomplainform;
