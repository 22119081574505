import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../components/Sidepanel';
import Navbar from '../components/Navbar';
import Dashboard from '../pages/dashboard/Dashboard';
import Users from '../pages/homepage/Users';
import Customers from '../pages/homepage/Customers';
import Tasks from '../pages/homepage/Tasks';
import Notes from '../pages/homepage/Notes';
import Orders from '../pages/homepage/Orders'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchCommision, deleteCommision } from '../api/api';

export default function SellerDetails() {
  const { id } = useParams(); // Get the commision id from the route parameters
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // logic 
  const [commision, setCommision] = useState([]);

  useEffect(() => {
    fetchCommisions();
  }, []);

  const fetchCommisions = async () => {
    try {
      const commision = await fetchCommision(id);
      console.log(commision);
      setCommision(commision);
    } catch (error) {
      console.error('Error fetching commision:', error);
    }
  };

  const handleDelete = async (userId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Item?");
    if (isConfirmed) {
      try {
        await deleteCommision(userId);
        fetchCommisions();
        toast.success('Item deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Item. Please try again.');
      }
    }
  };

    return (
        <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>

            <h6><u>Seller Details</u></h6>

            <table className="table table-striped">
            <thead>
              <tr>
                <td><strong>#</strong></td>
                <th>Date</th>
                <th>Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {commision.length > 0 ?
              (commision.map((comm, index) => (
                <tr key={comm.id}>
                  <td>{index + 1}</td>
                  <td>{comm.createdAt}</td>
                  <td>{comm.commision}</td>
                  <td>
                    {/* <i className="fa-solid fa-delete-left fa-1x" onClick={() => handleDelete(comm.id)} style={{ paddingLeft: '10px'}}></i> */}
                  </td>
                </tr>
              ))) : <div>Zero Commisions</div>}
            </tbody>
          </table>
          <ToastContainer />
          </div>
          }
          
          
          
          
          
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
    )
}
