import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllSales } from '../../api/api';

export default function Balereturn() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [sales, setSales] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  useEffect(() => {
    fetchSales();
  }, []);

  const fetchSales = async () => {
    try {
      const sales = await fetchAllSales();
      const filteredSales = sales.filter(sale => sale.cnqty > 0);
      console.log(filteredSales);
      setSales(filteredSales);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const filteredSales = sales.filter(sale => {
    const saleDate = new Date(sale.balereturndate);
    const start = new Date(startDate);
    const end = new Date(endDate);
    return (
      sale.customer.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (!startDate || saleDate >= start) &&
      (!endDate || saleDate <= end)
    );
  });

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>
            <h6><u>Bale Return</u></h6>
            <div className="d-flex justify-content-end mb-3">
              <input
                type="text"
                className="form-control w-25 me-2"
                placeholder="Search by customer"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <input
                type="date"
                className="form-control w-25 me-2"
                value={startDate}
                onChange={handleStartDateChange}
              />
              <input
                type="date"
                className="form-control w-25"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <td><strong>#</strong></td>
                  <th>Item</th>
                  <th>QTY</th>
                  <th className='text-danger'>CN.QTY</th>
                  <th>Price</th>
                  <th>Total</th>
                  <th>Sale Type</th>
                  <th>Status</th>
                  <th>Customer</th>
                  <th>Return Date</th>
                  <th></th>                
                </tr>
              </thead>
              <tbody>
                {filteredSales.map((sale, index) => (
                  <tr key={sale.id}>
                    <td>{index + 1}</td>
                    <td>{sale.itemid.item}</td>
                    <td>{sale.saleqty}</td>
                    <td className='text-danger'>{sale.cnqty}</td>
                    <td>{sale.saleprice}</td>
                    <td>{(sale.saleqty - sale.cnqty) * sale.saleprice}</td>
                    <td>{sale.saletype}</td>
                    <td>{sale.salestatus === 0 ? <p className='text-danger fw-bold'>Not sold</p> : <p className='text-success fw-bold'>Sold</p>}</td>
                    <td>{sale.customer}</td>
                    <td>{sale.balereturndate}</td>
                  </tr>
                ))}
              </tbody> 
            </table>
            <ToastContainer />
          </div>
          }

          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
