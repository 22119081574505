// Sellerform.js
import React from 'react';

const Sellerform = ({ formData, handleChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className='d-flex'>
        <input type="text" className="form-control d-none" id="username" name="username" value={formData.username} onChange={handleChange} />
        <input type="text" className="form-control" id="sellerName" name="sellername" placeholder='Add Seller' value={formData.sellerName} onChange={handleChange} required />
        <button type="submit" className="btn btn-success btn-sm mx-1">Add</button>
      </div>
    </form>
  );
};

export default Sellerform;
