// Notes.js
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notesform from '../../components/Notesform';
import { fetchingAllNotes, createNote, updateNote, deleteNote } from '../../api/api';

export default function Notes() {
  const [notes, setTasks] = useState([]);
  const [formData, setFormData] = useState({
    note: '',
    title: '',
    userid: localStorage.getItem('userid')
  });
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const notes = await fetchingAllNotes(localStorage.getItem('userid'));
      console.log(notes);
      setTasks(notes);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createNote(formData);
      fetchTasks();
      toast.success('Note added successfully.!');
    } catch (error) {
      toast.error('Error adding note. Please try again.');
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateNote(selectedTask.id, formData);
      fetchTasks();
      toast.success('Note updated successfully.');
      setSelectedTask(null);
    } catch (error) {
      toast.error('Error updating note. Please try again.');
    }
  };

  const handleUpdate = (note) => {
    setSelectedTask(note);
    setFormData({
      note: note.note,
      title: note.title,
    });
  };

  const handleDelete = async (userId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this note?");
    if (isConfirmed) {
      try {
        await deleteNote(userId);
        fetchTasks();
        toast.success('Note deleted successfully.');
      } catch (error) {
        toast.error('Error deleting note. Please try again.');
      }
    }
  };

  return (
    <>
      <div className='container mt-5'>
        <button className='btn btn-success btn-sm float-end' data-bs-toggle="modal" data-bs-target="#exampleModal">Create Note</button>

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title fs-5" id="exampleModalLabel">Add Note</h6>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <Notesform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
              </div>
            </div>
          </div>
        </div>

        <h5><u>Notes</u></h5>
        <table className="table table-striped">
          <thead>
            <tr>
              <td><strong>#</strong></td>
              <th>Title</th>
              <th>Note</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {notes.map((note, index) => (
              <tr key={note.id}>
                <td>{index + 1}</td>
                <td>{note.title}</td>
                <td>{note.note}</td>
                <td>{note.createdAt}</td>
                <td>
                  <i className="fa-solid fa-pen-to-square fa-1x" onClick={() => handleUpdate(note)} data-bs-toggle="modal" data-bs-target="#exampleModal2"></i>
                  <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h6 className="modal-title fs-5" id="exampleModalLabel2">Update Note</h6>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <Notesform formData={formData} handleChange={handleChange} handleSubmit={handleUpdateSubmit} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <i className="fa-solid fa-delete-left fa-1x" onClick={() => handleDelete(note.id)} style={{ paddingLeft: '10px'}}></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer />
      </div>
    </>
  );
}
