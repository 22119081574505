// Users.js
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Customerform from '../../components/Customerform';
import { fetchAllCustomers, createCustomer, updateCustomer, deleteCustomer } from '../../api/api';

export default function Customers() {
  const [customers, setCustomers] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    designation: 'customer',
    credit_limit: '',
    password: ''
  });
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const customers = await fetchAllCustomers();
      setCustomers(customers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createCustomer(formData);
      fetchCustomers();
      toast.success('Customer added successfully.!');
    } catch (error) {
      toast.error('Error adding user. Please try again.');
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateCustomer(selectedCustomer.id, formData);
      fetchCustomers();
      toast.success('Customer updated successfully.');
      setSelectedCustomer(null);
    } catch (error) {
      toast.error('Error updating customer. Please try again.');
    }
  };

  const handleUpdate = (customer) => {
    setSelectedCustomer(customer);
    setFormData({
      name: customer.name,
      phone: customer.phone,
      email: customer.email,
      designation: customer.designation,
      credit_limit: customer.credit_limit,
      password: ''
    });
  };

  const handleDelete = async (userId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this customer?");
    if (isConfirmed) {
      try {
        await deleteCustomer(userId);
        fetchCustomers();
        toast.success('Customer deleted successfully.');
      } catch (error) {
        toast.error('Error deleting customer. Please try again.');
      }
    }
  };

  return (
    <>
      <div className='container mt-5'>
        <button className='btn btn-success btn-sm float-end' data-bs-toggle="modal" data-bs-target="#exampleModal">Create Customer</button>

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title fs-5" id="exampleModalLabel">Create Customer</h6>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <Customerform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
              </div>
            </div>
          </div>
        </div>

        <h5><u>Customers</u></h5>
        <table className="table table-striped">
          <thead>
            <tr>
              <td><strong>#</strong></td>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Credit Limit</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer, index) => (
              <tr key={customer.id}>
                <td>{index + 1}</td>
                <td>{customer.name}</td>
                <td>{customer.email}</td>
                <td>{customer.phone}</td>
                <td>{customer.credit_limit}</td>
                <td>
                  <i class="fa-solid fa-eye"></i>
                  <i className="fa-solid fa-pen-to-square fa-1x mx-2" onClick={() => handleUpdate(customer)} data-bs-toggle="modal" data-bs-target="#exampleModal2"></i>
                  <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h6 className="modal-title fs-5" id="exampleModalLabel2">Customer User</h6>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <Customerform formData={formData} handleChange={handleChange} handleSubmit={handleUpdateSubmit} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <i className="fa-solid fa-delete-left fa-1x" onClick={() => handleDelete(customer.id)}></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer />
      </div>
    </>
  );
}
